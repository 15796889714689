import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { TwitterPicker } from 'react-color';
import classNames from 'classnames';

import URLS from '../../constants/urls'
import { TWITTER_PICKER_COLORS, TABS } from '../../constants/index'
import { updateMapsEditor, saveMapEditor } from '../../actions/mapsEditorActions';
import { createFlash } from '../../shared/createFlash';

import MapsEditorStylePicker from './MapsEditorStylePicker';
import MapsEditorTextOverrides from './MapsEditorTextOverrides';
import MapsEditorAdvancedTheming from './MapsEditorAdvancedTheming';
import MapsEditorGeographySettings from './MapsEditorGeographySettings';
import MapsEditorImageSettings from './MapsEditorImageSettings';
import MapsEditorMobileSettings from './MapsEditorMobileSettings';
import MapsEditorPinSettings from './MapsEditorPinSettings';
import MapFontFamilySelector from './MapFontFamilySelector';

export class MapsEditorForm extends Component {
  state = {
    activeTab: 'general'
  };

  handleTabClick = (name) => {
    return () => {
      this.setState({ activeTab: name });
    }
  }

  handleColorChange = (color) => {
    this.props.updateMapsEditor({ primary_color: color.hex })
  }

  handleChange = (name) => {
    return event => {
      const { target: { value } } = event;
      if (name === 'default_position') {
        this.props.updateMapsEditor({ 
          default_viewport_sw_longitude: null,
          default_viewport_sw_latitude: null,
          default_viewport_ne_longitude: null,
          default_viewport_ne_latitude: null, 
          specific_location_id: null,
        });
      };
      this.props.updateMapsEditor({ [name]: value });
    }
  }

  handleSelectChange = (name) => {
    return (selectedOption) => {
      this.props.updateMapsEditor({ [name]: selectedOption.value });
    }
  }

  handleCheckboxChange = (name) => {
    return event => {
      this.props.updateMapsEditor({ [name]: event.target.checked });
    }
  }

  handleSubmit = (event) => {
    event.stopPropagation()
    event.preventDefault()

    this.props.saveMapEditor(this.props.mapId, { map: this.props })
    .then(json => {
      createFlash('Your theming changes have been saved!', 'success')
    }).catch((error) => {
      createFlash('Sorry, there was a problem saving your changes.', 'error')
    })
  }

  get renderDistanceUnits() {
    const { units } = this.props;

    return (
      <div>
        <div className="maps-editor-label">
          Distance Units
          <div className="maps-editor-sublabel">
            When users view your map this is the units of distance they will see.
          </div>
        </div>
        <div className="maps-editor-form-group form-radio-group">
          {
            [{value: 'mi', label: 'Miles (mi)'}, {value: 'km', label: 'Kilometers (km)'}].map((unit) => {
              return (
                <label className="form-radio-label" key={unit.value}>
                  <input
                    type="radio"
                    name={unit.value}
                    value={unit.value}
                    checked={units === unit.value}
                    onChange={this.handleChange('units')}
                    className="form-radio-input"
                  />
                  <div className="form-radio-label-copy">
                    {unit.label}
                  </div>
                </label>
              )
            })
          }
        </div>
      </div>
    )
  }

  get renderHoursFormat() {
    const { hours_format } = this.props;

    return (
      <div>
        <div className="maps-editor-label">
          Hours Formatting
        </div>
        <div className="maps-editor-form-group form-radio-group">
          {
            [{value: 'format_12_hours', label: '12-Hour Clock (12AM - 11:59PM)'}, {value: 'format_24_hours', label: '24-Hour Clock (00:00 - 23:59)'}].map((format) => {
              return (
                <label className="form-radio-label" key={format.value}>
                  <input
                    type="radio"
                    name={format.value}
                    value={format.value}
                    checked={hours_format === format.value}
                    onChange={this.handleChange('hours_format')}
                    className="form-radio-input"
                  />
                  <div className="form-radio-label-copy">
                    {format.label}
                  </div>
                </label>
              )
            })
          }
        </div>
      </div>
    )
  }

  get renderResultsLimit() {
    const { results_limit, show_results_summary } = this.props;
    const options = [
      { value: 1000, label: '1000 Location Results' },
      { value: 3000, label: '3000 Location Results' },
      { value: 5000, label: '5000 Location Results' },
      { value: 10000, label: '10000 Location Results' },
    ];

    const summaryOptions = [
      { value: true, label: 'Show the location results summary' },
      { value: false, label: 'Hide the total location results summary' },
    ];

    return (
      <div className="maps-editor-form-group">
        <div className="maps-editor-label">
          Results Limit
          <div className="maps-editor-sublabel">
            This limit applies when there are more locations than can be shown in a specific map area (usually when showing larger areas like countries). A higher limit can result in slower loading times.
          </div>
        </div>
        <div>
          <Select
            name="results_limit"
            className="dropdown-select"
            options={options}
            value={options.find(option => option.value === results_limit)}
            onChange={this.handleSelectChange('results_limit')}
            clearable={false}
            isSearchable={false}
            menuShouldScrollIntoView
            menuPlacement={'auto'}
          />
        </div>
        <div className="mtm">
          <div className="maps-editor-sublabel">
            Show the summary of locations found. This is displayed at the bottom of your map's location list (ie "Showing 200 within 50 mi").
          </div>
          <div className="mtm">
            <Select
              name="show_results_summary"
              className="dropdown-select"
              options={summaryOptions}
              value={summaryOptions.find(option => option.value === show_results_summary)}
              onChange={this.handleSelectChange('show_results_summary')}
              clearable={false}
              isSearchable={false}
              menuShouldScrollIntoView
              menuPlacement={'auto'}
            />
          </div>
        </div>
      </div>
    )
  }

  get renderLauncherText() {
    const {
      launcher_enabled,
      launcher_text,
      shopify_launcher_script_required
    } = this.props;

    if (!launcher_enabled || shopify_launcher_script_required) return;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Launcher Text
          <div className="maps-editor-sublabel">
            This is the text that will be displayed when using the default launcher in the corner of your website.
          </div>
        </div>
        <div>
          <input
            type="text"
            name="launcher_text"
            value={ launcher_text }
            onChange={ this.handleChange('launcher_text') }
            className="form-control"
            placeholder="Our Locations"
            maxLength="30"
            required
          />
        </div>
      </div>
    );
  }

  get renderLauncherSettings() {
    const {
      launcher_enabled,
      launcher_text,
      shopify_launcher_script_required,
      hasWixConnected
    } = this.props;

    if (hasWixConnected) return;

    return (
      <div>
        {shopify_launcher_script_required ? (
          <div className="pbxl">
            <div className="maps-editor-label">
              Enable Launcher
            </div>
            <div className="maps-editor-shopify-launcher-required">
              You must enable the "Shopify Overlay" on your <a href="/maps/install">map installation</a> settings to use the launcher on your Shopify store.
            </div>
          </div>
        ) : (
          <div className="pbxl">
            <div className="maps-editor-label">
              Enable Launcher
              <div className="maps-editor-sublabel">
                Note: Disabling the launcher will require you to manually open your map by using buttons/links on your website.
              </div>
            </div>
            <label className="form-checkbox-label">
              <input
                type="checkbox"
                name="launcher_enabled"
                className="form-checkbox-input"
                checked={ launcher_enabled }
                onChange={ this.handleCheckboxChange('launcher_enabled') }
              />
              <div className="form-checkbox-label-copy">
                Enable default launcher on my website
              </div>
            </label>
          </div>
        )}

        {this.renderLauncherText}
      </div>
    )
  }

  get renderOpenAsNewTab() {
    const { open_as_new_tab } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Custom Button Links
          <div className="maps-editor-sublabel">
            This option only applies when custom buttons are added to your locations.
          </div>
        </div>
        <label className="form-checkbox-label">
          <input
            type="checkbox"
            name="open_as_new_tab"
            className="form-checkbox-input"
            checked={ open_as_new_tab }
            onChange={ this.handleCheckboxChange('open_as_new_tab') }
          />
          <div className="form-checkbox-label-copy">
            Open custom button links in a new tab
          </div>
        </label>
      </div>
    )
  }

  get renderButtonSetting() {
    const {
      button_setting,
    } = this.props;

    const options = [
      { value: 'custom_button_overrides_directions', label: 'Override direction button with custom buttons' },
      { value: 'always_show_directions', label: 'Always show directions (even if custom button added)' },
      { value: 'always_hide_directions', label: 'Always hide directions (even if custom button added)' }
    ];

    return (
      <div>
        <div className="maps-editor-label">
          Showing "Get Directions" Button
          <div className="maps-editor-sublabel">
            This setting will determine when the "Get Directions" button is shown on your map in relation to any custom buttons you add.
          </div>
        </div>
        <div className="maps-editor-form-group">
          <Select
            name="button_setting"
            className="dropdown-select"
            options={options}
            value={options.find(option => option.value === button_setting)}
            onChange={this.handleSelectChange('button_setting')}
            clearable={false}
            isSearchable={false}
            menuShouldScrollIntoView
            menuPlacement={'auto'}
          />
        </div>
      </div>
    )
  };

  get renderClosedDaysOption() {
    const { show_closed_days } = this.props;
    const options = [
      { value: 'do_not_show', label: 'Do not show closed days' },
      { value: 'show_weekends', label: 'Show weekends as closed when no hours set' },
    ];

    return (
      <div>
        <div className="maps-editor-label">
          Showing Closed Days
          <div className="maps-editor-sublabel">
            This will show days as "Closed" if no hours have been set on a location.
          </div>
        </div>
        <div className="maps-editor-form-group">
          <Select
            name="show_closed_days"
            className="dropdown-select"
            options={options}
            value={options.find(option => option.value === show_closed_days)}
            onChange={this.handleSelectChange('show_closed_days')}
            clearable={false}
            isSearchable={false}
            menuShouldScrollIntoView
            menuPlacement={'auto'}
          />
        </div>
      </div>
    );
  }

  get renderHideLocationCategories() {
    const { hide_location_categories } = this.props;
    const options = [
      { value: false, label: 'Show categories when viewing location details' },
      { value: true, label: 'Hide categories when viewing location details' },
    ];

    return (
      <div>
        <div className="maps-editor-label">
          Location Category Display
          <div className="maps-editor-sublabel">
            This will hide/show the categories when viewing a location in your map.
          </div>
        </div>
        <div className="maps-editor-form-group">
          <Select
            name="hide_location_categories"
            className="dropdown-select"
            options={options}
            value={options.find(option => option.value === hide_location_categories)}
            onChange={this.handleSelectChange('hide_location_categories')}
            clearable={false}
            isSearchable={false}
            menuShouldScrollIntoView
            menuPlacement={'auto'}
          />
        </div>
      </div>
    )
  }

  get renderDetailsPreference() {
    const {
      location_detail_preference
    } = this.props;

    return (
      <div>
        <div className="maps-editor-label">
          Popup Preference
          <div className="maps-editor-sublabel">
            Change the details shown on your map when a location's popup is displayed.
          </div>
        </div>
        <div className="maps-editor-form-group form-radio-group">
          {
            [{ value: 'details_hours_first', label: 'Prioritize days open (ie "Open Mon-Fri")' }, { value: 'details_phone_first', label: 'Prioritize phone number' }, { value: 'details_website_first', label: 'Prioritize website URL' }].map((option) => {
              return (
                <label className="form-radio-label" key={option.value}>
                  <input
                    type="radio"
                    name={option.value}
                    value={option.value}
                    checked={location_detail_preference === option.value}
                    onChange={this.handleChange('location_detail_preference')}
                    className="form-radio-input"
                  />
                  <div className="form-radio-label-copy">
                    {option.label}
                  </div>
                </label>
              )
            })
          }
        </div>
      </div>
    )
  }

  get renderScrollZoom() {
    const {
      scroll_zoom_enabled,
      show_fullscreen,
    } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Scroll Zoom & Full-Screen Viewing
          <div className="maps-editor-sublabel">
            Allow or prevent zooming on your map by scrolling on a trackpad (on laptops). On mobile, users must always use 2-finger gestures to zoom. "Full-screen viewing" allow users to view the map portion in full screen.
          </div>
        </div>
        <div className="mbs">
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="scroll_zoom_enabled"
              className="form-checkbox-input"
              checked={ scroll_zoom_enabled }
              onChange={ this.handleCheckboxChange('scroll_zoom_enabled') }
            />
            <div className="form-checkbox-label-copy">
              Enable scroll zoom on my map
            </div>
          </label>
        </div>
        <div>
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="show_fullscreen"
              className="form-checkbox-input"
              checked={ show_fullscreen }
              onChange={ this.handleCheckboxChange('show_fullscreen') }
            />
            <div className="form-checkbox-label-copy">
              Enable full-screen viewing on my map
            </div>
          </label>
        </div>
      </div>
    )
  }

  get renderAutoRefresh() {
    const {
      auto_refresh
    } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Auto-Refresh Map Locations
          <div className="maps-editor-sublabel">
            By default, your map will prompt the user to refresh the search area when moving the map position. This is considered smoother experience for most maps, but in some cases such as hyper-localized map viewing, it may be preferable to always auto-refresh results instead.
          </div>
        </div>
        <div className="mbs">
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="scroll_zoom_enabled"
              className="form-checkbox-input"
              checked={ auto_refresh }
              onChange={ this.handleCheckboxChange('auto_refresh') }
            />
            <div className="form-checkbox-label-copy">
              Auto-refresh location results when the map is moved
            </div>
          </label>
        </div>
      </div>
    )
  }

  get renderLayoutSetting() {
    const { list_style } = this.props;
    const MAP_STYLE_OPTIONS = [
      { value: 'standard_list_style', label: 'Standard Location List (default)' },
      { value: 'image_list_style', label: 'Location & Image List' },
      { value: 'column_list_style', label: '2-Column Location Image List' },
    ];

    return (
      <div className="maps-editor-form-group">
        <div className="form-radio-group">
          {
            MAP_STYLE_OPTIONS.map(({ value, label }) => (
              <label className="form-radio-label" key={value}>
                <input
                  type="radio"
                  name={value}
                  value={value}
                  checked={list_style === value}
                  onChange={this.handleChange('list_style')}
                  className="form-radio-input"
                />
                <div className="form-radio-label-copy">
                  {label}
                </div>
              </label>
            ))
          }
        </div>
        <div className="maps-editor-default-position-notice">
          <div
            className={classNames('maps-editor-layout-web-preview', list_style)}
          />
        </div>
      </div>
    )
  }

  get renderButtonStyle() {
    const { button_style } = this.props;
    const options = [
      { value: 'button_rounded', label: 'Completely rounded buttons (default)' },
      { value: 'button_rectangular_sharp', label: 'Rectangular buttons with sharp edges' },
      { value: 'button_rectangular_rounded', label: 'Rectangular buttons with rounded edges' }
    ];

    return (
      <div>
        <div className="maps-editor-label">
          Button Style
          <div className="maps-editor-sublabel">
            This style applies to the "Directions" button and any custom button you add.
          </div>
        </div>
        <div className="maps-editor-form-group">
          <Select
            name="button_style"
            className="dropdown-select"
            options={options}
            value={options.find(({ value }) => value === button_style)}
            onChange={this.handleSelectChange('button_style')}
            menuShouldScrollIntoView
            menuPlacement="auto"
          />
        </div>
      </div>
    );
  }

  get renderGeneral() {
    const {
      suggestion_range,
      suggestion_strict,
      primary_color,
    } = this.props;

    return (
      <div className="maps-editor-form-group">
        <div className="row">
          <div className="col-md-6 prxl-md">
            <div className="maps-editor-label">
              Full Layout
            </div>
            {this.renderLayoutSetting}
            {this.renderScrollZoom}
            {this.renderDetailsPreference}
            {this.renderButtonSetting}
            {this.renderOpenAsNewTab}
            {this.renderLauncherSettings}
          </div>
          <div className="col-md-6">
            <div className="maps-editor-form-group">
              <div className="maps-editor-label">
                Primary Color
                <div className="maps-editor-sublabel">
                  Darker shade recommended. This color is used for defaults such as location buttons and links, map markers, and popups.
                </div>
              </div>

              <TwitterPicker
                color={primary_color}
                onChangeComplete={this.handleColorChange}
                triangle="hide"
                colors={TWITTER_PICKER_COLORS}
              />
            </div>
            <div className="maps-editor-form-group">
              <MapFontFamilySelector />
            </div>
            {this.renderButtonStyle}
            {this.renderAutoRefresh}
            {this.renderLocationCluster}
            {this.renderLocationReposition}
            <div>
              <div className="maps-editor-label">
                Suggestion Range
                <div className="maps-editor-sublabel">
                  This configuration is used when an autocomplete suggestion is selected.  "Bounding Box" will let users see all locations within the suggested coordinates for a location, and "Near Center" will show all locations within a 50 mile range of the center point.
                </div>
              </div>
              <div className="maps-editor-form-group form-radio-group">
                {
                  [{value: 'bounding_box', label: 'Bounding Box (default)'}, {value: 'near_center', label: 'Near Center'}].map((option) => {
                    return (
                      <div>
                        <label className="form-radio-label" key={option.value}>
                          <input
                            type="radio"
                            name={option.value}
                            value={option.value}
                            checked={suggestion_range === option.value}
                            onChange={this.handleChange('suggestion_range')}
                            className="form-radio-input"
                          />
                          <div className="form-radio-label-copy">
                            {option.label}
                          </div>
                        </label>
                        {option.value === 'bounding_box' && suggestion_range === 'bounding_box' && (
                          <div className="mbm plm">
                            <label className="form-checkbox-label">
                              <input
                                type="checkbox"
                                name="suggestion_strict"
                                className="form-checkbox-input"
                                checked={ suggestion_strict }
                                onChange={ this.handleCheckboxChange('suggestion_strict') }
                              />
                              <div className="form-checkbox-label-copy">
                                Do not show nearby locations when no results are found.
                              </div>
                            </label>
                          </div>
                        )}
                        {option.value === 'bounding_box' && suggestion_range !== 'bounding_box' && (
                          <div className="mbs"/>
                        )}
                      </div>
                    )
                  })
                }
              </div>
            </div>
            {this.renderDistanceUnits}
            {this.renderHoursFormat}
            {this.renderResultsLimit}
            {this.renderClosedDaysOption}
            {this.renderHideLocationCategories}
          </div>
        </div>
      </div>
    )
  }

  get renderActiveTabContent() {
    const { activeTab } = this.state;
  
    switch (activeTab) {
      case 'general':
        return this.renderGeneral;
      case 'geography':
        return <MapsEditorGeographySettings />;
      case 'image':
        return <MapsEditorImageSettings />;
      case 'mobile':
        return <MapsEditorMobileSettings />;
      case 'pin':
        return <MapsEditorPinSettings />;
      case 'theme':
        return <MapsEditorStylePicker />;
      case 'text':
        return <MapsEditorTextOverrides />;
      case 'advanced':
        return <MapsEditorAdvancedTheming />;
      default:
        return null;
    }
  };

  render() {
    return (
      <div className="page-overlay clearfix">
        <div className="maps-editor-form-header">
          <div className="wrapper">
            <div className="maps-editor-form-header-in">
              <h2 className="page-overlay-title">
                Edit Theme
              </h2>
              <a className="btn btn-default mrs" href={URLS.MAPS.INDEX}>View My Map</a>
              <a className="btn btn-primary" onClick={this.handleSubmit}>Save Changes</a>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="page-overlay-content">
            <div className="page-overlay-content-tabs">
              {
                TABS.map(({ key, label }) => (
                  <div
                    key={key}
                    className={classNames('page-overlay-content-tab', {
                      active: this.state.activeTab === key,
                    })}
                    onClick={this.handleTabClick(key)}
                  >
                    {label}
                  </div>
                ))
              }
            </div>
            {this.renderActiveTabContent}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.mapsEditor
  }
};

export default connect(mapStateToProps, { updateMapsEditor, saveMapEditor })(MapsEditorForm);
