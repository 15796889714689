import React, { Component } from 'react';
import URLS from '../../constants/urls'

export class MapsHeader extends Component {
  render() {
    const { mapKey } = this.props

    return (
      <div className="page-header clearfix">
        <div className="page-header-title">
          Map Theming
        </div>
        <div className="page-header-actions">
          <a href={URLS.MARKETING.FEATURES} className="page-header-link" target="_blank">
            <i className="page-header-link-icon ion-help-buoy"></i>Features
          </a>
          <a href={URLS.FAQ.INDEX} className="page-header-link nth-link" target="_blank">
            <i className="page-header-link-icon ion-help-circled"></i>FAQ
          </a>
        </div>
      </div>
    );
  }
}

export default MapsHeader
