import API_ENDPOINTS from '../constants/apiEndpoints'
import apiClient from '../services/apiClient'
import { createFlash } from '../shared/createFlash'

import {
  GET_LOCATIONS,
  LOCATIONS_UPDATE_LIST,
  LOCATION_SHOW_FORM,
  LOCATION_HIDE_FORM,
  LOCATION_UPDATE_FORM_VALUES,
  LOCATION_SAVE_FORM_VALUES
} from '../constants';

export const showLocation = (values) => {
  return {
    type: LOCATION_SHOW_FORM,
    values
  };
}

export const hideLocation = (values) => {
  return {
    type: LOCATION_HIDE_FORM,
    values
  };
}

export const getLocations = (params) => (dispatch) => {
  apiClient.get(API_ENDPOINTS.LOCATIONS.INDEX, {
    params: {
      page_num: (params && params.page_num ? params.page_num : 1),
      search: (params && params.search ? params.search : ''),
    }
  })
  .then(response => {
    dispatch(requestGetLocations(response.data))
  })
}

const requestGetLocations = (json) => {
  return {
    type: GET_LOCATIONS,
    json
  };
}

export const updateLocationsList = (values) => {
  return {
    type: LOCATIONS_UPDATE_LIST,
    values
  };
}

export const updateLocation = (values) => {
  return {
    type: LOCATION_UPDATE_FORM_VALUES,
    values
  };
}

export const saveLocation = (id, params) => (dispatch) => {
  let request;
  const formData = new FormData();
  const { location } = params;

  const appendFormData = (key, value) => {
    formData.append(`location[${key}]`, value);
  };

  const appendArrayToFormData = (key, array) => {
    if (array.length === 0) {
      appendFormData(`${key}[]`, '');
    } else {
      array.forEach(item => {
        formData.append(`location[${key}][]`, item);
      });
    }
  };

  const handleSecondaryImages = () => {
    if (location.secondary_images.length === 0) {
      appendFormData('secondary_images[]', '');
    } else {
      location.secondary_images.forEach(file => {
        const value = file.signed_id || file.file;
        formData.append('location[secondary_images][]', value);
      });
    }
  };

  const handleLocationHours = () => {
    location.location_hours.forEach((hour) => {
      ['day', 'time_open', 'time_close'].forEach((field) => {
        formData.append(`location[location_hours][][${field}]`, hour[field]);
      });
      if (hour.id) {
        appendFormData('location_hours][][id]', hour.id);
      }
      if (hour._destroy) {
        appendFormData('location_hours][][_destroy]', hour._destroy);
      }
    });
  };

  Object.entries(location).forEach(([key, value]) => {
    switch (key) {
      case 'secondary_images':
        handleSecondaryImages();
        break;
      case 'location_hours':
        handleLocationHours();
        break;
      case 'categories':
        appendArrayToFormData(key, value);
        break;
      default:
        appendFormData(key, value);
    }
  });

  if (id && id !== '') {
    request = apiClient.patch(API_ENDPOINTS.LOCATIONS.UPDATE(id), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(response => response.data);
  } else {
    request = apiClient.post(API_ENDPOINTS.LOCATIONS.CREATE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(response => response.data);
  }

  return request;
};

export const deleteLocation = (id, params) => (dispatch) => {
  return apiClient.delete(API_ENDPOINTS.LOCATIONS.DESTROY(id), params)
}

export const showGoogleSheetUneditableFlashError = () => {
  return createFlash('Locations cannot be edited on Closeby with Google Sheets enabled.' , 'error')
}
