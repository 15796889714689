import React, { Component } from 'react';
import { connect } from 'react-redux';
import Geosuggest from 'react-geosuggest';
import Select from 'react-select';
import TagsInput from 'react-tagsinput';
import Autosuggest from 'react-autosuggest';
import without from 'lodash.without';

import { COUNTRY_CODES } from '../../constants/index';
import { DEFAULT_POSITION_TEXTS, DEFAULT_POSITION_OPTIONS, AUTOCOMPLETE_OPTIONS} from '../../constants/index';

import { updateMapsEditor } from '../../actions/mapsEditorActions';

import MapsEditorDefaultMap from './MapsEditorDefaultMap';
import RadioInput from './RadioInput';

export class MapsEditorGeographySettings extends Component {
  handleEditorChange = (name) => (value) => 
    this.props.updateMapsEditor({ [name]: value });
  
  handleCheckboxChange = (name) => ({ target: { checked } }) => 
    this.props.updateMapsEditor({ [name]: checked });
  
  handleChange = (name) => ({ target: { value } }) => 
    this.props.updateMapsEditor({ [name]: value });

  handleSpecificLocationChange = (fieldName) => (selectedOption) => {
    if (!selectedOption) {
      this.props.updateMapsEditor({
        specific_location_id: null,
        default_viewport_sw_longitude: null,
        default_viewport_sw_latitude: null,
        default_viewport_ne_longitude: null,
        default_viewport_ne_latitude: null,
      });
      return;
    }

    const { latitude, longitude } = selectedOption;

    if (!latitude || !longitude) return;

    const boundsOffset = 0.0015;

    const southwestCoordinates = [longitude - boundsOffset, latitude - boundsOffset];
    const northEastCoordinates = [longitude + boundsOffset, latitude + boundsOffset];

    this.props.updateMapsEditor({
      specific_location_id: selectedOption.value,
      default_viewport_sw_longitude: southwestCoordinates[0],
      default_viewport_sw_latitude: southwestCoordinates[1],
      default_viewport_ne_longitude: northEastCoordinates[0],
      default_viewport_ne_latitude: northEastCoordinates[1],
    });
  };

  onViewportSuggestSelect = (suggestion) => {
    if (!suggestion) return
    const bounds = suggestion.gmaps.geometry && suggestion.gmaps.geometry.viewport

    if (!bounds) {
      return console.error('Expected suggestion.gmaps.geometry.bounds', suggestion)
    }

    const sw = bounds.getSouthWest()
    const ne = bounds.getNorthEast()

    if (!(sw && ne)) {
      return console.error('Expected bounds sw and ne', bounds)
    }

    if (sw && ne) {
      this.props.updateMapsEditor({
        default_viewport_label: suggestion.label,
        default_viewport_sw_longitude: sw.lng(),
        default_viewport_sw_latitude: sw.lat(),
        default_viewport_ne_longitude: ne.lng(),
        default_viewport_ne_latitude: ne.lat()
      })
    }
  };

  removeDefaultViewport = () => {
    this.props.updateMapsEditor({
      default_viewport_label: '',
      default_viewport_sw_longitude: '',
      default_viewport_sw_latitude: '',
      default_viewport_ne_longitude: '',
      default_viewport_ne_latitude: ''
    })
  }

  get renderDefaultMapView() {
    const {
      appMapboxKey,
      default_viewport_label,
      default_viewport_sw_longitude,
      default_viewport_sw_latitude,
      default_viewport_ne_longitude,
      default_viewport_ne_latitude,
    } = this.props;

    return (
      <>
        <div className="maps-editor-default-map-input">
          <Geosuggest
            autocomplete="off"
            placeholder='Search city, region or country'
            inputClassName='form-control'
            onSuggestSelect={this.onViewportSuggestSelect}
            initialValue={default_viewport_label ? default_viewport_label : ''}
            ref={node => { this.geoSuggestNode = node }}
          />
          {default_viewport_label && (
            <div className="maps-editor-default-map-remove" onClick={this.removeDefaultViewport}>
              Remove
            </div>
          )}
        </div>
        <MapsEditorDefaultMap
          southwestCoordinates={[default_viewport_sw_longitude, default_viewport_sw_latitude]}
          northEastCoordinates={[default_viewport_ne_longitude, default_viewport_ne_latitude]}
          appMapboxKey={appMapboxKey}
        />
      </>
    );
  };

  get renderSpecificLocationMapView() {
    const {
      appMapboxKey,
      locations,
      default_viewport_sw_longitude,
      default_viewport_sw_latitude,
      default_viewport_ne_longitude,
      default_viewport_ne_latitude,
      specific_location_id,
    } = this.props;

    const locations_options = locations.map(({ id, address_full, latitude, longitude }) => ({
      value: id,
      label: address_full,
      latitude,
      longitude
    }));

    const arraySouthwestCoordinates = [default_viewport_sw_longitude, default_viewport_sw_latitude];
    const arrayNortheastCoordinates = [default_viewport_ne_longitude, default_viewport_ne_latitude];

    return (
      <>
        <div className="maps-editor-default-map-input">
          <Select
            name="specific_location_id"
            className="dropdown-select"
            placeholder="Select Location..."
            options={locations_options}
            value={locations_options.find(({ value }) => value === specific_location_id)}
            onChange={this.handleSpecificLocationChange('specific_location_id')}
            isClearable
            isSearchable
            menuShouldScrollIntoView
            menuPlacement={'auto'}
          />
        </div>
        <MapsEditorDefaultMap
          southwestCoordinates={arraySouthwestCoordinates}
          northEastCoordinates={arrayNortheastCoordinates}
          appMapboxKey={appMapboxKey}
        />
      </>
    );
  };

  renderDefaultPositionNotice = () => {
    const { default_position } = this.props;
    const texts = DEFAULT_POSITION_TEXTS[default_position];

    if (!texts) return null;

    switch (default_position) {
      case 'specific_location':
        return (
          <div className="maps-editor-default-position-notice">
            <div className="maps-editor-default-position-copy">
              <p>{texts.notice}</p>
            </div>
            <div>{this.renderSpecificLocationMapView}</div>
          </div>
        );
      case 'show_all':
      case 'geolocate':
      case 'viewport':
        return (
          <div className="maps-editor-default-position-notice">
            {texts.details && (
              <div className="maps-editor-default-position-details">
                {texts.details}
              </div>
            )}
            {texts.copy && (
              <div className="maps-editor-default-position-copy">
                {texts.copy.map((paragraph) => (
                  <p key={paragraph}>{paragraph}</p>
                ))}
              </div>
            )}
            {this.renderDefaultMapView}
          </div>
        );
      default:
        return null;
    }
  };

  get renderDefaultMapPosition() {
    const {
      default_position,
    } = this.props;

    return (
      <div className="maps-editor-form-group">
        <div className="maps-editor-label">
          Default Map Position
        </div>
        <div className="maps-editor-form-group">
          <div className="form-radio-group">
            {DEFAULT_POSITION_OPTIONS.map(({ value, label }) => (
              <RadioInput
                key={value}
                name="default_position"
                value={value}
                checked={default_position === value}
                onChange={this.handleChange('default_position')}
                label={label}
              />
            ))}
          </div>
          {this.renderDefaultPositionNotice()}
        </div>
      </div>
    )
  }

  get renderAutocompleteCountry() {
    const {
      suggestion_countries
    } = this.props;

    const suggestionOptions = Object.keys(COUNTRY_CODES);

    function autocompleteRenderInput({ addTag, ...props }) {
      const handleOnChange = (e) => { e.preventDefault() };
      const suggestions = without(props.suggestions, ...suggestion_countries)

      return (
        <Autosuggest
          ref={props.ref}
          suggestions={suggestions}
          shouldRenderSuggestions={() => true}
          getSuggestionValue={(suggestion) => suggestion}
          renderSuggestion={(suggestion) => <span>{COUNTRY_CODES[suggestion]} ({suggestion})</span>}
          inputValue=""
          inputProps={{ ...props, onChange: handleOnChange, onKeyPress: handleOnChange }}
          onSuggestionSelected={(_, { suggestion }) => {
            addTag(suggestion)
          }}
          onSuggestionsClearRequested={() => { }}
          onSuggestionsFetchRequested={() => { }}
        />
      )
    }

    return (
      <div className="maps-editor-form-group">
        <div className="maps-editor-label">
          Autocomplete Country Restriction
          <div className="maps-editor-sublabel">
            Select the countries you would like to restrict suggestions to. This is useful if you have locations in a specific country and want to prevent users from searching in other countries.
          </div>
        </div>
        <div className="maps-editor-form-group">
          <TagsInput
            value={suggestion_countries}
            onChange={this.handleEditorChange('suggestion_countries')}
            onlyUnique
            addOnBlur
            inputProps={{
              placeholder: 'Select country',
              suggestions: suggestionOptions,
            }}
            renderInput={autocompleteRenderInput}
          />
        </div>
      </div>
    )
  }

  get renderLocationReposition() {
    const { location_reposition } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Location Repositioning
          <div className="maps-editor-sublabel">
            When enabled, this will automatically reposition the map to center on the selected location when a user clicks on a location in the list.
          </div>
        </div>
        <div className="mbs">
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="location_reposition"
              className="form-checkbox-input"
              checked={location_reposition}
              onChange={this.handleCheckboxChange('location_reposition')}
            />
            <div className="form-checkbox-label-copy">
              Fly to location when selected in the list
            </div>
          </label>
        </div>
      </div>
    )
  }


  get renderGeneral() {
    const {
      suggestion_type,
    } = this.props;

    return (
      <div className="maps-editor-form-group">
        <div className="row">
          <div className="col-md-6 prxl-md">
            {this.renderDefaultMapPosition}
          </div>
          <div className="col-md-6">
            {this.renderLocationReposition}
            <div className="maps-editor-form-group">
              <div className="maps-editor-label">
                Autocomplete Suggestions
                <div className="maps-editor-sublabel">
                  Select the types of geographic locations you would like to be autocompleted when your visitors type in your map's search bar.
                </div>
              </div>
              <div className="maps-editor-form-group form-radio-group">
                {AUTOCOMPLETE_OPTIONS.map(({ value, label }) => (
                  <label className="form-radio-label" key={value}>
                    <input
                      type="radio"
                      name={value}
                      value={value}
                      checked={suggestion_type === value}
                      onChange={this.handleChange('suggestion_type')}
                      className="form-radio-input"
                    />
                    <div className="form-radio-label-copy">
                      {label}
                    </div>
                  </label>
                ))}
              </div>
            </div>
            {this.renderAutocompleteCountry}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return this.renderGeneral;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.mapsEditor
  }
};

export default connect(mapStateToProps, { updateMapsEditor })(MapsEditorGeographySettings);
