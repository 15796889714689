import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateMapsEditor } from '../../actions/mapsEditorActions';

import MapsEditorLocationImageForm from './MapsEditorLocationImageForm';
import { MapsEditorMarkerImage } from './MapsEditorMarkerImage';

export class MapsEditorImageSettings extends Component {
  handleCheckboxChange = (name) => (event) => {
    this.props.updateMapsEditor({ [name]: event.target.checked });
  };

  handleImageChange = (locationDefaultImage) => {
    this.props.updateMapsEditor({
      location_default_image: locationDefaultImage,
    });
  };

  renderLocationDefaultImage = () => {
    const imageMaxFileSize = 10000000;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Default Location Image
          <div className="maps-editor-sublabel">
            Upload a default image to use for locations that don't have an image set. PNG (recommended), JPG and JPEG formats supported.
          </div>
        </div>
        <MapsEditorMarkerImage
          name="location_default_image"
          value={this.props.location_default_image}
          handleChange={this.handleImageChange}
          s3MarkerFormData={this.props.s3MarkerFormData}
          s3MarkerPostUrl={this.props.s3MarkerPostUrl}
          s3MarkerPostHost={this.props.s3MarkerPostHost}
          maxFileSize={imageMaxFileSize}
        />
      </div>
    );
  };

  renderCategoriesLocationImages = () => {
    const { category_location_images_enabled } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Category Location Images
          <div className="maps-editor-sublabel">
            Set a default location image for each category. Location images will be displayed in the location popup when a user clicks on a location pin.
          </div>
        </div>
        <div>
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="category_location_images_enabled"
              className="form-checkbox-input"
              checked={category_location_images_enabled}
              onChange={this.handleCheckboxChange('category_location_images_enabled')}
            />
            <div className="form-checkbox-label-copy">
              Enable category-specific location images
            </div>
          </label>
          {category_location_images_enabled && (
            <div className="maps-editor-categories-list">
              {this.renderCategoryLocationImages()}
            </div>
          )}
        </div>
      </div>
    );
  };

  renderCategoryLocationImages = () => {
    const { categories, categories_attributes } = this.props;
    if (!categories?.length) return null;

    return categories.map(({ id, name, location_image }) => (
      <MapsEditorLocationImageForm
        key={id}
        id={id}
        name={name}
        location_image={location_image}
        categories_attributes={categories_attributes}
      />
    ));
  };

  render() {
    return (
      <div className="maps-editor-form-group">
        <div className="row">
          <div className="col-md-6 prxl-md">
            {this.renderLocationDefaultImage()}
          </div>
          <div className="col-md-6">
            {this.renderCategoriesLocationImages()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.mapsEditor,
});

export default connect(mapStateToProps, { updateMapsEditor })(MapsEditorImageSettings);
