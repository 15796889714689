import React from 'react';
import PropTypes from 'prop-types';

const RadioInput = ({ name, value, checked, onChange, label }) => (
  <label className="form-radio-label">
    <input
      type="radio"
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      className="form-radio-input"
    />
    <div className="form-radio-label-copy">{label}</div>
  </label>
);

RadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default RadioInput;
