import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import { create, show, index } from '../../actions/importTaskActions'
import URLS from '../../constants/urls'

import ImportTasksList from '../import_tasks/ImportTasksList'
import ImportCsvInstructions from '../import_tasks/ImportCsvInstructions'
import GoogleSheetsInstructions from '../import_tasks/GoogleSheetsInstructions'
import LocatorSpinner from '../locator/LocatorSpinner'
import IntegrationsHeader from './IntegrationsHeader'

export default class IntegrationsIndex extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hidingUploadForm: false,
      pageIsLoading: true
    }
  }

  componentDidMount () {
    this.fetchTaskList()
  }

  fetchTaskList () {
    index().then((resp) => {
      this.setState({
        importTasks: resp.import_tasks || [],
        hidingUploadForm: resp.import_tasks && resp.import_tasks.length > 0,
        pageIsLoading: false,
      })
    }).catch(this.handleError.bind(this))
  }

  updateImportTask = (importTaskId) => {
    return show(importTaskId).then((resp) => {
      const { importTasks } = this.state
      const idx = importTasks.findIndex((task) => {
        return +task.id === +importTaskId
      })
      importTasks[idx] = resp.import_task
      this.setState({
        importTasks
      })
    }).catch(this.handleError.bind(this))
  }

  showUploadForm = () => {
    this.setState({ hidingUploadForm: false })
  }

  get someAreProcessing () {
    const { importTasks } = this.state
    return !!(
      importTasks &&
      importTasks.find((it) => it.state === 'processing')
    )
  }

  filePicked = (fileInput) => {
    if (!fileInput) {
      return this.handleError(new Error('Missing fileinput'))
    }
    this.setState({ isUploading: true })
    const file = fileInput.files[0]
    create(file).then((resp) => {
      this.fetchTaskList().then(() => {
        this.setState({ isUploading: false, resp })
      }).catch(this.handleError.bind(this))
    }).catch(this.handleError.bind(this))
  }

  forceSync = () => {
    this.setState({ isUploading: true })
    create().then((resp) => {
      this.fetchTaskList().then(() => {
        this.setState({ isUploading: false, resp })
      }).catch(this.handleError.bind(this))
    }).catch(this.handleError.bind(this))
  }

  handleError (error) {
    this.setState({
      isUploading: false,
      errorMessage: error && error.message || 'Woops, something went wrong',
      pageIsLoading: false,
    })
  }

  render () {
    const { hidingUploadForm, isUploading, pageIsLoading, errorMessage, importTasks } = this.state
    const { type, isFree } = this.props
    const someAreProcessing = this.someAreProcessing

    return (
      <div>
        <IntegrationsHeader type={type} />

        <div className="integrations-content">
          <div className="wrapper-800">

            { false ? (
              <div className="mbxl text-right">
                <div className="btn-group" role="group" aria-label="Basic example">
                  <a href={URLS.INTEGRATIONS.GOOGLE_SHEETS} className={classNames('btn', type === 'GOOGLE_SHEETS' ? 'btn-primary active' : 'btn-default')}>Google Sheets</a>
                  <a href={URLS.INTEGRATIONS.CSV} className={classNames('btn', type === 'CSV' ? 'btn-primary active' : 'btn-default')}>CSV Import</a>
                </div>
              </div>
            ): ''}

            { type === 'GOOGLE_SHEETS' ?
              <GoogleSheetsInstructions
                onForceSync={this.forceSync}
                someAreProcessing={someAreProcessing}
                isFree={isFree}
              /> : null
            }
            { type === 'CSV' ?
              <ImportCsvInstructions
                importTasks={importTasks || []}
                isUploading={this.state.isUploading}
                hidingUploadForm={hidingUploadForm}
                onFilePicked={this.filePicked}
                showUploadForm={this.showUploadForm}
                someAreProcessing={someAreProcessing}
              /> : null
            }
            { pageIsLoading ? (
              <div className="integrations-instructions">
                <div className="text-center">
                  <LocatorSpinner />
                </div>
              </div>
            ) : null }

            {importTasks && importTasks.length ? (
              console.log(importTasks),
              <div className="mtxl">
                <h2 id='sync-history'>Import History</h2>
                <ImportTasksList
                  importTasks={importTasks || []}
                  hidingUploadForm={hidingUploadForm}
                  updateImportTask={this.updateImportTask}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

IntegrationsIndex.propTypes = {
  isFree: PropTypes.bool
}
