import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateMapsEditor } from '../../actions/mapsEditorActions';

export class MapsEditorMobileSettings extends Component {
  handleCheckboxChange = (name) => {
    return event => {
      this.props.updateMapsEditor({ [name]: event.target.checked });
    }
  }

  handleChange = (name) => {
    return event => {
      const { target: { value } } = event;
      this.props.updateMapsEditor({ [name]: value });
    }
  }

  renderMobileLayoutSetting = () => {
    const { mobile_style } = this.props;
    const MOBILE_STYLE_OPTIONS = [
      { value: 'mobile_map_list_style', label: 'Small Map & List View (default)' },
      { value: 'mobile_map_search_style', label: 'Full Map & Search View' },
    ];

    return (
      <div className="maps-editor-form-group">
        <div className="form-radio-group">
          {
            MOBILE_STYLE_OPTIONS.map(({ value, label }) => (
              <div key={value}>
                <label className="form-radio-label">
                  <input
                    type="radio"
                    name={value}
                    value={value}
                    checked={mobile_style === value}
                    onChange={this.handleChange('mobile_style')}
                    className="form-radio-input"
                  />
                  <div className="form-radio-label-copy">
                    {label}
                  </div>
                </label>
                {value === 'mobile_map_search_style' && (
                  <div className="maps-editor-layout-mobile-note">
                    Note: Users can toggle the still list view when viewing the map
                  </div>
                )}
              </div>
            ))            
          }
        </div>
        <div className="maps-editor-default-position-notice">
          <div className={`maps-editor-layout-web-preview ${mobile_style}`} />
        </div>
      </div>
    )
  }

  get renderSingleFingerScroll() {
    const {
      enable_single_finger_scroll
    } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Single Finger Scroll
          <div className="maps-editor-sublabel">
            By default, map interaction on a mobile device requires two fingers - "one finger to scroll the page, two fingers to scroll the map". If you set single finger scroll, mobile users will only be able to scroll the page outside of the map.
          </div>
        </div>
        <div className="mbs">
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="enable_single_finger_scroll"
              className="form-checkbox-input"
              checked={ enable_single_finger_scroll }
              onChange={ this.handleCheckboxChange('enable_single_finger_scroll') }
            />
            <div className="form-checkbox-label-copy">
              Enable single finger scroll view on my map
            </div>
          </label>
        </div>
      </div>
    )
  }

  get renderGeneral() {
    return (
      <div className="maps-editor-form-group">
        <div className="row">
          <div className="col-md-6 prxl-md">
            <div className="maps-editor-label">
              Mobile Layout
            </div>
            <div>
              {this.renderMobileLayoutSetting()}
            </div>
          </div>
          <div className="col-md-6">
            {this.renderSingleFingerScroll}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return this.renderGeneral;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.mapsEditor
  }
};

export default connect(mapStateToProps, { updateMapsEditor })(MapsEditorMobileSettings);
