import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';

import { ZENDESK_LINK } from '../../constants';

import { updateMapsEditor } from '../../actions/mapsEditorActions';

import { MapsEditorMarkerImage } from './MapsEditorMarkerImage';
import MapsEditorCategoryForm from './MapsEditorCategoryForm';

export class MapsEditorPinSettings extends Component {
  handleCheckboxChange = (name) => {
    return event => {
      this.props.updateMapsEditor({ [name]: event.target.checked });
    }
  }

  handleSelectChange = (name) => {
    return (selectedOption) => {
      this.props.updateMapsEditor({ [name]: selectedOption.value });
    }
  }

  handleMarkerChange = backgroundUrl => {
    this.props.updateMapsEditor({
      marker_background_url: backgroundUrl
    })
  }

  get renderLocationCluster() {
    const { enable_location_cluster } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Cluster Locations
          <div className="maps-editor-sublabel">
            When enabled, this will group your location pins together at lower zoom levels. This is useful when you have many locations in a specific area that make individual pins difficult to see.
            <a href={ZENDESK_LINK} target="_blank">Learn More</a>
          </div>
        </div>
        <div className="mbs">
          <label className="form-checkbox-label">
            <input
              type="checkbox"
              name="scroll_zoom_enabled"
              className="form-checkbox-input"
              checked={enable_location_cluster}
              onChange={this.handleCheckboxChange('enable_location_cluster')}
            />
            <div className="form-checkbox-label-copy">
              Enable location clustering when zoomed out
            </div>
          </label>
        </div>
      </div>
    )
  }

  get renderMarkerIconImage() {
    const { marker_style } = this.props;
    const markerMaxFileSize = 75000;

    if (marker_style !== 'marker_custom') return;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          Default Custom Marker Icon
          <div className="maps-editor-sublabel">
            Upload a default custom icon image 60px x 80px to use as the marker icon on your map. PNG (recommended), JPG and JPEG formats supported. This will override any category colors you set for your location pins.
          </div>
        </div>
        <MapsEditorMarkerImage
          name="marker_background_url"
          value={this.props.marker_background_url}
          handleChange={this.handleMarkerChange}
          s3MarkerFormData={this.props.s3MarkerFormData}
          s3MarkerPostUrl={this.props.s3MarkerPostUrl}
          s3MarkerPostHost={this.props.s3MarkerPostHost}
          maxFileSize={markerMaxFileSize}
        />
      </div>
    )
  }

  get renderMarkerStyle() {
    const { marker_style } = this.props;
    const options = [
      { value: 'marker_default', label: 'Default Map Pin' },
      { value: 'marker_circle', label: 'Circle Icon' },
      { value: 'marker_custom', label: 'Custom Image' },
    ];

    return (
      <>
        <div className="maps-editor-label">
          Pin Style
        </div>
        <div className="maps-editor-form-group">
          <Select
            name="marker_style"
            className="dropdown-select"
            options={options}
            value={options.find(({ value })=> value === marker_style)}
            onChange={this.handleSelectChange('marker_style')}
            menuShouldScrollIntoView
            menuPlacement="auto"
          />
        </div>

        <div
          className={classNames({
            'maps-editor-form-marker-preview': ['marker_default', 'marker_circle'].includes(marker_style),
            'default-pin': marker_style === 'marker_default',
            'circle-pin': marker_style === 'marker_circle',
          })}
        />
      </>
    );
  }

  get renderCategories() {
    const { category_colors_enabled, category_images_enabled, marker_style } = this.props;

    return (
      <div className="pbxl">
        <div className="maps-editor-label">
          { marker_style !== 'marker_custom' ? 'Pin Colors' : 'Pin Images' }
          <div className="maps-editor-sublabel">
            Set specific { marker_style !== 'marker_custom' ? 'colors' : 'images'} for each category to display for your visitors. Location pins on your map will use the { marker_style !== 'marker_custom' ? 'colors' : 'images'} of the first category added to it. Category { marker_style !== 'marker_custom' ? 'colors' : 'images'} will not be used if zoomed out and locations are clustered.
          </div>
        </div>
        <>
          {marker_style !== 'marker_custom' ? (
            <label className="form-checkbox-label">
              <input
                type="checkbox"
                name="category_colors_enabled"
                className="form-checkbox-input"
                checked={category_colors_enabled}
                onChange={this.handleCheckboxChange('category_colors_enabled')}
              />
              <div className="form-checkbox-label-copy">
                Enable category-specific colors
              </div>
            </label>
          ) : (
            <label className="form-checkbox-label">
              <input
                type="checkbox"
                name="category_images_enabled"
                className="form-checkbox-input"
                checked={category_images_enabled}
                onChange={this.handleCheckboxChange('category_images_enabled')}
              />
              <div className="form-checkbox-label-copy">
                Enable category-specific images
              </div>
            </label>
          )}
          {( (marker_style !== 'marker_custom' && category_colors_enabled) || (marker_style == 'marker_custom' && category_images_enabled)) && (
            <div className="maps-editor-categories-list">
              {this.renderCategoryColors}
            </div>
          )}
        </>
      </div>
    )
  }

  get renderCategoryColors() {
    const { categories, categories_attributes, primary_color } = this.props;
    if (!categories || categories.length === 0) return;

    return categories.map(({id, name, marker_background_url, color}) => (
        <MapsEditorCategoryForm 
          id={id}
          name={name}
          primaryColor={primary_color}
          image={marker_background_url}
          color={color}
          key={id}
          categories_attributes={categories_attributes} 
        />
      )
    )
  }

  get renderGeneral() {
    return (
      <div className="maps-editor-form-group">
        <div className="row">
          <div className="col-md-6 prxl-md">
            {this.renderMarkerStyle}
            {this.renderMarkerIconImage}
            {this.renderLocationCluster}
          </div>
          <div className="col-md-6">
            {this.renderCategories}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return this.renderGeneral;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.mapsEditor
  }
};

export default connect(mapStateToProps, { updateMapsEditor })(MapsEditorPinSettings);
